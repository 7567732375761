import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/equipment",
    name: "Equipment",
    component: () => import("../views/Equipment.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/download",
    name: "Download",
    component: () => import("../views/Download.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior(to, from, saveTop) {
  //   if (saveTop) {
  //     if (to.name === "/equipment/#Features" && from.name === "/equipment") {
  //       return saveTop;
  //     }
  //   }

  //   return { left: 0, top: 0 };
  // },
});

export default router;
