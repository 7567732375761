<template>
  <div class="common-contact">
    <form @submit="onsubmit" class="form">
      <div class="label">
        <p>Name</p>
        <input
          type="text"
          name="name"
          v-model="info.name"
          required
          oninvalid="setCustomValidity('Please enter your Name');"
          oninput="setCustomValidity('');"
        />
      </div>

      <div class="label">
        <p>Work email</p>
        <input
          type="email"
          name="email"
          v-model="info.email"
          required
          oninvalid="setCustomValidity('Please write a valid email address');"
          oninput="setCustomValidity('');"
        />
      </div>

      <div class="label">
        <p>Company website</p>
        <input type="text" name="website" v-model="info.website" />
      </div>

      <div class="label">
        <p>Country</p>
        <input type="text" name="country" v-model="info.country" />
      </div>

      <div class="label">
        <p>Phone</p>
        <input type="text" name="phone" v-model="info.phone" />
      </div>

      <div class="label">
        <p>Information</p>
        <textarea
          type="textarea"
          name="message"
          v-model="info.message"
          required
          oninvalid="setCustomValidity('Please write the message');"
          oninput="setCustomValidity('');"
        />
      </div>
      <button class="button" type="submit" value="send">Send</button>
    </form>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Contact",
  setup() {
    const info = reactive({
      name: "",
      email: "",
      website: "",
      country: "",
      phone: "",
      message: "",
    });
    const onsubmit = (event) => {
      event.preventDefault();
      axios({
        method: "post",
        url: "/api/kcheck/add_contact",
        data: {
          name: info.name,
          email: info.email,
          website: info.website,
          country: info.country,
          phone: info.phone,
          message: info.message,
        },
      })
        .then((res) => {
          if (res.data.success) {
            alert("Send Successfully!");
          } else {
            alert("Send Failed");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      onsubmit,
      info,
    };
  },
});
</script>

<style lang="less" scoped>
.common-contact {
  width: 724px;
  height: 664px;
  margin: 0 auto;
  background: url("~@/assets/images/contact/contact.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    width: 500px;
    .label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      input {
        background: #f4f6fa;
        border: none;
        border-radius: 4px;
        width: 289px;
        height: 40px;
        font-size: 16px;
        font-family: PingFang-SC;
        color: #a0a5ab;
        padding-left: 10px;
      }
      input::-webkit-input-placeholder {
        color: #a0a5ab;
      }
      input::-moz-input-placeholder {
        color: #a0a5ab;
      }
      input::-ms-input-placeholder {
        color: #a0a5ab;
      }
      textarea::-webkit-input-placeholder {
        color: #a0a5ab;
      }
      textarea::-moz-input-placeholder {
        color: #a0a5ab;
      }
      textarea::-ms-input-placeholder {
        color: #a0a5ab;
      }
      textarea {
        width: 289px;
        height: 122px;
        background: #f4f6fa;
        border: none;
        font-size: 16px;
        font-family: PingFang-SC;
        color: #a0a5ab;
        padding-left: 10px;
      }
    }
    .button {
      width: 106px;
      height: 35px;
      background: #00a7e1;
      border: 1px solid #d2d2d2;
      border-radius: 20px;
      color: #ffffff;
      float: right;
      margin-top: 10px;
      cursor: pointer;
    }
  }
}
</style>
