<template>
  <div class="home">
    <!-- 头部内容 -->
    <div class="header">
      <Banner>
        <img src="@/assets/images/logoBlue.png" />
      </Banner>
      <div class="home-des">
        <p class="des-top">
          THE MOST ADVANCED AUTOMATED DIAGNOSTIC TEST SOFTWARE
        </p>
        <p class="des-down">
          The most accurate and cloud based solution for testing used devices in
          the industry
        </p>
        <!-- <div class="video" @click="showFirstVideo">
          <button>
            <img src="@/assets/images/video.png" />
          </button>
        </div> -->
      </div>
    </div>
    <div class="firstVideo" v-if="firstVideo === true">
      <video class="video" controls autoplay>
        <source
          src="https://ksites.oss-cn-shenzhen.aliyuncs.com/k-check/video/APP.mp4"
          type="video/mp4"
        />
      </video>
      <div class="remove" @click="shutFirstVideo">
        <div class="icon">
          <img src="@/assets/images/remove.png" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content1">
        <p>
          The industry standard for device certification, diagnostics and <br />
          Data Destruction for iOS & Android.
        </p>
        <div class="img">
          <img src="@/assets/images/home/k-check.png" title="K-Check Phone" />
        </div>
      </div>
      <div class="content2">
        <div class="top">
          <div class="img">
            <img src="@/assets/images/home/c2phone1.png" />
          </div>
          <p>
            Hardware automated diagnostics allows each unit to be categorized by
            it's failure for proper parts replacement and reuse.
          </p>
        </div>
        <div class="middle">
          <div class="middleImg1">
            <img src="@/assets/images/home/c2phone2.png" />
          </div>
          <div class="middleImg2">
            <img src="@/assets/images/home/c2phone3.png" />
          </div>
        </div>
        <div class="bottom">
          <p>
            By restoring a unit to working condition, verified by K-Check, the
            end product is a reliable, tested device, at a fraction of a new
            price
          </p>
          <div class="img">
            <img src="@/assets/images/home/c2phone4.png" />
          </div>
        </div>
      </div>
      <div class="content3">
        <div class="top">
          <p>
            K-Check offers an unmatched set of customizable solutions for mobile
            device processing.We have expertise in cutting edge machines,
            artificial intelligence and software development to enhance software
            solutions to deliver maximum value and efficiency to our customers.
          </p>
        </div>
        <div class="bottom">
          <div class="video" @click="showSecondVideo">
            <button>
              <img src="@/assets/images/video.png" />
            </button>
          </div>
        </div>

        <div class="secondVideo" v-if="secondVideo === true">
          <video class="video" controls autoplay>
            <source
              src="https://ksites.oss-cn-shenzhen.aliyuncs.com/k-check/video/MAC.mp4"
              type="video/mp4"
            />
          </video>
          <div class="remove" @click="shutSecondVideo">
            <div class="icon">
              <img src="@/assets/images/home/video2Remove.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="content4">
        <div class="one">
          <div class="image">
            <img src="@/assets/images/home/c4icon.png" />
          </div>
        </div>
        <div class="two">
          <div class="image">
            <img src="@/assets/images/home/c4icon1.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/c4icon2.png" />
          </div>
        </div>
        <!-- <div class="wrap"> -->
        <div class="three">
          <div class="image">
            <img src="@/assets/images/home/c4icon3.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/c4icon4.png" />
          </div>
        </div>
        <div class="four">
          <div class="image">
            <img src="@/assets/images/home/c4icon5.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/c4icon6.png" />
          </div>
        </div>
        <!-- </div> -->
        <div class="five">
          <div class="image">
            <img src="@/assets/images/home/c4icon7.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/c4icon8.png" />
          </div>
        </div>
        <div class="six">
          <div class="image">
            <img src="@/assets/images/home/c4icon9.png" />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="content5">
          <div class="left">
            <p class="top">Know your numbers to increase your numbers</p>
            <p class="middle">
              Every test collects data points throughout the process that are
              stored on the K-Check Cloud so that you, the customer can not only
              track the data destruction of a device, but t's original hardware
              as well. Our online analytics provide an array of customizable
              solutions for reporting and tracking your devices.
            </p>
            <div class="bottom">
              <ul>
                <li>Current Market Trading Reports</li>
                <li>Export Reports</li>
                <li>Under the multilevel drill</li>
                <li>Redundant, same as above, erase</li>
              </ul>
            </div>
          </div>
          <div class="image">
            <img src="@/assets/images/home/c5img.png" />
          </div>
        </div>
        <div class="content6">
          <div class="image">
            <img src="@/assets/images/home/c6icon.png" />
          </div>
          <div class="right">
            <p>Two-in-one solution</p>
            <p>
              From receipts to resale and everything in between, K-Check gives
              you the tools you need to optimize the handling of your mobile
              device.
            </p>
            <p>
              We will create a custom solution that reduces， waste and provides
              high quality service from start to finish
            </p>
          </div>
        </div>
        <div class="content7">
          <div class="image">
            <img src="@/assets/images/home/c7icon.png" />
          </div>
          <div class="right">
            <div class="border1">
              <ul>
                <li>enhance</li>
                <li>productivity</li>
                <li>consistency</li>
                <li>The output value</li>
                <li>Worker safety</li>
                <li>quality</li>
              </ul>
            </div>
            <div class="border2">
              <ul>
                <li>reduce</li>
                <li>processing</li>
                <li>labor</li>
                <li>Manual tasks</li>
                <li>mistake</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="content8">
        <div class="left">
          <img src="@/assets/images/home/c8icon.jpg" />
        </div>
        <div class="right">
          <p class="title">We develop cutting-edge tools</p>
          <p class="des">
            From the beginning, our focus has been to work closely with our
            customers as a trusted business partner, pioneering innovative new
            technologies and setting new industry standards for accuracy and
            efficiency.Combined with our deep understanding of mobile device
            programming and the adoption of Lean Six Sigma concepts, we are able
            to simplify and enhance our customers' operations.
          </p>
        </div>
      </div>
    </div>
    <div class="contact-delivery">
      <ContactUs />
      <!-- <div class="imageWrap">
        <div class="top">
          <div class="image">
            <img src="@/assets/images/home/icon.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/icon1.png" />
          </div>
        </div>
        <div class="bottom">
          <div class="image">
            <img src="@/assets/images/home/icon2.png" />
          </div>
          <div class="image">
            <img src="@/assets/images/home/icon3.png" />
          </div>
        </div>
      </div> -->
    </div>

    <!-- footer -->
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Banner from "@/components/common/Banner.vue";
import ContactUs from "@/components/common/Contact.vue";
// import { Button } from "ant-design-vue";

export default defineComponent({
  name: "Home",
  components: {
    Banner,
    ContactUs,
    // [Button.name]: Button,
  },
  setup() {
    const firstVideo = ref("false");
    const secondVideo = ref("false");

    const showFirstVideo = () => {
      firstVideo.value = true;
    };
    const shutFirstVideo = () => {
      firstVideo.value = false;
    };

    const showSecondVideo = () => {
      secondVideo.value = true;
    };
    const shutSecondVideo = () => {
      secondVideo.value = false;
    };

    return {
      showFirstVideo,
      firstVideo,
      secondVideo,
      shutFirstVideo,
      showSecondVideo,
      shutSecondVideo,
    };
  },
});
</script>

<style lang="less" scoped>
.home {
  .header {
    background-image: url("~@/assets/images/home/homeBg.png");
    background-size: cover;
    height: 873px;
    .home-des {
      margin-top: 144px;
      margin-left: 238px;
      .des-top {
        width: 582px;
        height: 84px;
        font-size: 36px;
        font-family: Abhaya Libre SemiBold;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
      }
      .des-down {
        width: 645px;
        height: 56px;
        font-size: 24px;
        margin-top: 30px;
        font-family: Abhaya Libre ExtraBold;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
      }
      .video {
        text-align: left;
        margin-top: 40px;
        button {
          background: transparent;
          border: none;
          cursor: pointer;
        }
        .videoIcon {
          width: 103px;
          height: 38px;
          opacity: 1;
          color: white;
        }
      }
    }
  }
  .content {
    .content1 {
      margin-top: 70px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-image: url("~@/assets/images/home/content1Bg.png");
      background-size: cover;
      P {
        width: 691px;
        height: 126px;
        font-size: 36px;
        font-family: Abhaya Libre ExtraBold;
        font-weight: 400;
        color: #ffffff;
      }
      .img {
        width: 203px;
        height: 410px;
        margin-top: 98px;
        img {
          width: 100%;
        }
      }
    }
    .content2 {
      margin-top: 235px;
      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          width: 290px;
          height: 269px;
          img {
            width: 100%;
          }
        }
        p {
          margin-left: 239px;
          width: 570px;
          height: 102px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          text-align: LEFT;
          color: #000000;
          line-height: 35px;
        }
      }
      .middle {
        display: flex;
        justify-content: center;
        margin-top: 228px;
        .middleImg1 {
          width: 526px;
          height: 350px;
          img {
            width: 100%;
          }
        }
        .middleImg2 {
          width: 524px;
          height: 350px;
          margin-left: 160px;
          img {
            width: 100%;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-evenly;
        margin-top: 163px;
        p {
          margin-top: 0px;
          width: 458px;
          height: 136px;
          font-size: 24px;
          font-weight: 400;
          text-align: LEFT;
          color: #000000;
          line-height: 35px;
        }

        .img {
          width: 424px;
          height: 242px;
          img {
            width: 100%;
          }
        }
      }
    }
    .content3 {
      height: 488px;
      display: flex;
      margin-top: 74px;
      flex-direction: column;
      background-image: url("~@/assets/images/home/c3contentBg.png");
      background-size: cover;
      position: relative;
      .top {
        p {
          width: 939px;
          height: 210px;
          opacity: 1;
          font-size: 31px;
          font-family: Abhaya Libre ExtraBold;
          font-weight: 400;
          text-align: CENTER;
          color: #ffffff;
          margin: 0 auto;
          margin-top: 89px;
          line-height: 46px;
        }
      }
      .bottom {
        margin-top: 35px;
        button {
          background: transparent;
          border: none;
          cursor: pointer;
        }
      }
      .secondVideo {
        width: 900px;
        height: 500px;
        // border: 1px dotted #00ff66;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        .video {
          z-index: 100;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 500px;
          min-width: 100%;
          min-height: 100%;
          // object-fit: fill; /*这里是关键*/
          // width: auto;
          // height: auto;
          -ms-transform: translateX(-50%) translateY(-50%);
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          // background: url('~@/assets/images/home/homeBg.png') no-repeat;
          // background-size: cover;
        }
        .remove {
          position: absolute;
          top: 15px;
          right: 25px;
          cursor: pointer;
          z-index: 200;
          .icon {
            width: 25px;
            height: 25px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .content4 {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 79px;
      .one,
      .two,
      .three,
      .four,
      .five {
        .image {
          width: 64px;
          height: 63px;
          cursor: pointer;
          :hover {
            transform: scale(2);
            transition: all 0.5s ease-in-out;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .one {
        margin: 0 auto;
      }
      .two {
        display: flex;
        justify-content: center;
        margin-bottom: 44px;
        .image {
          // width: 64px;
          // height: 63px;
          &:last-of-type {
            margin-left: 204px;
          }
          // img {
          //   width: 100%;
          // }
        }
      }
      .three {
        display: flex;
        justify-content: center;
        margin-bottom: 73px;
        .image {
          // width: 64px;
          // height: 63px;
          &:last-of-type {
            margin-left: 318px;
          }
          // img {
          //   width: 100%;
          // }
        }
      }
      .four {
        display: flex;
        justify-content: center;
        margin-bottom: 47px;
        .image {
          // width: 64px;
          // height: 63px;
          &:last-of-type {
            margin-left: 318px;
          }
          // img {
          //   width: 100%;
          // }
        }
      }
      .five {
        display: flex;
        justify-content: center;
        .image {
          // width: 64px;
          // height: 63px;
          &:last-of-type {
            margin-left: 109px;
          }
          // img {
          //   width: 100%;
          // }
        }
      }
      .six {
        position: absolute;
        left: 48%;
        top: 29%;
        margin-left: -11px;
        .image {
          width: 104px;
          height: 209px;
          img {
            width: 100%;
          }
        }
      }
    }
    .wrap {
      // margin-left: 355px;
      // margin-right: 220px;
      margin: 0 106px;
      .content5 {
        display: flex;
        justify-content: center;
        margin-top: 168px;
        .left {
          display: flex;
          flex-direction: column;
          .top {
            width: 475px;
            height: 76px;
            font-size: 36px;
            font-family: Abhaya Libre ExtraBold;
            font-weight: 400;
            text-align: LEFT;
            color: #000000;
            margin-top: 0;
            margin-bottom: 0;
          }
          .middle {
            width: 567px;
            font-size: 24px;
            font-family: PingFang-SC;
            font-weight: 400;
            text-align: LEFT;
            color: #000000;
            margin-bottom: 0;
            line-height: 34px;
            margin-bottom: 30px;
          }
          .bottom {
            ul {
              padding: 0;
              li {
                font-family: PingFang-SC;
                text-align: left;
                list-style: none;
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 13px;
                &:last-of-type {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
        .image {
          width: 711px;
          height: 393px;
          margin-left: 67px;
          img {
            width: 100%;
          }
        }
      }
      .content6 {
        display: flex;
        justify-content: space-around;
        margin-top: 162px;
        .image {
          width: 409px;
          height: 256px;
          img {
            width: 100%;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          width: 450px;
          height: 300px;
          text-align: left;
          margin-right: 200px;
          p {
            &:first-of-type {
              font-size: 48px;
              font-family: Abhaya Libre SemiBold;
              font-weight: 400;
              text-align: LEFT;
              margin-bottom: 0px;
            }
            font-size: 20px;
            font-weight: 400;
            text-align: LEFT;
            line-height: 30px;
          }
        }
      }
      .content7 {
        display: flex;
        justify-content: space-around;
        margin-top: 162px;
        .image {
          width: 363px;
          height: 250px;
          img {
            width: 100%;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          margin-right: 250px;
          .border1,
          .border2 {
            border: 1px solid lightgray;
            border-radius: 20px;
            margin-left: 25px;
            text-align: left;
            padding-left: 20px;
            ul {
              padding: 0px;
              li {
                list-style: none;
                line-height: 30px;
              }
            }
          }
          .border1 {
            width: 160px;
            height: 205px;
          }
          .border2 {
            width: 180px;
            height: 180px;
          }
        }
      }
    }
    .content8 {
      background-image: url("~@/assets/images/home/c8Bg.jpg");
      background-size: cover;
      height: 498px;
      margin-top: 122px;
      margin-bottom: 122px;
      display: flex;
      justify-content: space-around;
      .right {
        width: 694px;
        height: 498px;
        display: flex;
        flex-direction: column;
        .title {
          margin-bottom: 0px;
          height: 57px;
          font-size: 48px;
          font-family: Abhaya Libre SemiBold;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
        }
        .des {
          margin-top: 20px;
          width: 694px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
          line-height: 35px;
        }
      }
    }
  }
  .contact-delivery {
    display: flex;
    justify-content: center;
    align-items: center;
    .common-contact {
      margin: 0px;
    }
    .imageWrap {
      display: flex;
      flex-direction: column;
      .top,
      .bottom {
        display: flex;
        .image {
          width: 130px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .firstVideo {
    width: 800px;
    height: 500px;
    // border: 1px dotted #00ff66;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .video {
      z-index: 100;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 500px;
      min-width: 100%;
      min-height: 100%;
      // object-fit: fill; /*这里是关键*/
      // width: auto;
      // height: auto;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      // background: url('~@/assets/images/home/homeBg.png') no-repeat;
      // background-size: cover;
    }
    .remove {
      position: absolute;
      top: 38px;
      right: 15px;
      cursor: pointer;
      z-index: 200;
      .icon {
        width: 25px;
        height: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
