<template>
  <div class="nav-banner">
    <div class="left">
      <router-link to="/">
        <div class="logo">
          <slot />
        </div>
      </router-link>
      <router-link to="/">
        <div class="title">K-CHECK</div>
      </router-link>
    </div>
    <div class="right">
      <router-link to="/equipment">
        <span>Equipment</span>
      </router-link>
      <router-link to="/about">
        <span>About us</span>
      </router-link>
      <router-link to="/contact">
        <span>Contact us</span>
      </router-link>
      <router-link to="/download">
        <span>Download</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Banner",
  setup() {},
});
</script>

<style lang="less">
.nav-banner {
  display: flex;
  align-items: center;
  height: 110px;
  .left {
    display: flex;
    align-items: center;
    margin-left: 303px;
    .logo {
      margin-right: 25px;
      margin-top: -6px;
    }
    .title {
      font-size: 24px;
      font-family: Abhaya Libre ExtraBold, Abhaya Libre ExtraBold-Regular;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .right {
    display: flex;
    margin-left: 129px;
    span {
      margin-right: 48px;
      color: #ffffff;
      font-size: 18px;
      font-family: Abhaya Libre SemiBold, Abhaya Libre SemiBold-Regular;
      font-weight: 400;
    }
  }
}
</style>
