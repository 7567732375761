<template>
  <div class="footer">
    <div class="logo">
      <img src="@/assets/images/logo.svg" />
    </div>
    <div class="detail">
      <div class="footer-wrap">
        <div class="k-check">
          <div class="logo-right">
            <a href="/">
              <span class="title">K-CHECK</span>
            </a>
            <img src="@/assets/images/footer/KintelLogo.png" />
          </div>
        </div>
        <div class="site-map">
          <ul>
            <li class="footer-nav">SITE MAP</li>
            <a href="/equipment"><li>Equipment</li></a>
            <a href="/contact">
              <li>Contact us</li>
            </a>
            <a href="/about">
              <li>About us</li>
            </a>
          </ul>
        </div>
        <div class="above">
          <div class="above-left">
            <ul>
              <!-- <router-link to="/equipment/#Features">
                <li>Features</li>
              </router-link>
              <router-link to="/equipment/#Compatibility">
                <li>Compatibility</li>
              </router-link> -->
              <a href="/equipment/#Features">
                <li>Features</li>
              </a>
              <a href="/equipment/#Compatibility">
                <li>Compatibility</li>
              </a>
              <router-link to="/equipment/#Automation">
                <li>Automation</li>
              </router-link>
              <router-link to="/equipment/#Data">
                <li>Data Scrubbing</li>
              </router-link>
              <router-link to="/equipment/#Cloud">
                <li>Cloud Based Results</li>
              </router-link>
            </ul>
          </div>
          <div class="above-right">
            <ul>
              <router-link to="/equipment/#Support">
                <li>Support</li>
              </router-link>
              <router-link to="/equipment/#Detection">
                <li>Detection</li>
              </router-link>
              <router-link to="/equipment/#Diagnosis">
                <li>Complete Diagnosis</li>
              </router-link>
              <router-link to="/equipment/#History">
                <li>History and Reality</li>
              </router-link>
              <router-link to="/equipment/#Resale">
                <li>Increase Resale Value</li>
              </router-link>
            </ul>
          </div>
        </div>
      </div>
      <div class="copy-right">
        <div class="pending">
          <span>Copyright © 2021 K-Check，LLC.</span>
        </div>

        <div class="media">
          <div class="fb">
            <a
              href="https://www.facebook.com/Rightphone-ES-102349785306282"
              target="_blank"
            >
              <img src="@/assets/images/footer/fb.png" />
            </a>
          </div>
          <div class="in">
            <a href="https://www.instagram.com/rightphonees/" target="_blank">
              <img src="@/assets/images/footer/ins.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Footer",
  setup() {
    const erasure = ref("true");
    return {
      erasure,
    };
  },
});
</script>

<style lang="less" scoped>
.footer {
  height: 273px;
  width: 100%;
  background: #414141;
  display: flex;
  // align-items: baseline;
  justify-content: center;
  padding-top: 107px;
  .logo {
    margin-right: 5px;
  }
  .detail {
    width: 1096px;
    ul {
      padding: 0;

      li {
        list-style: none;
        line-height: 22px;
        font-size: 14px;
        font-weight: 400;
        text-align: LEFT;
        color: #ffffff;
        // line-height: 22px;
        margin-bottom: 3px;
      }
    }
    .footer-wrap {
      display: flex;
      .k-check {
        display: flex;
        .logo-left {
          margin-top: 9px;
        }
        .logo-right {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          text-align: left;
          .title {
            height: 57px;
            font-size: 48px;
            font-family: Abhaya Libre ExtraBold;
            font-weight: 400;
            text-align: LEFT;
            color: #ffffff;
          }

          img {
            width: 220px;
          }
        }
      }
      .site-map {
        display: flex;
        flex-direction: column;
        margin-left: 208px;
        li {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 5px;
        }
        .footer-nav {
          line-height: 34px;
          font-size: 24px;
          font-weight: 400;
          text-align: LEFT;
          color: #ffffff;
        }

        // line-height: 18px;
        // }
      }
      .above {
        display: flex;
        margin-left: 103px;
        margin-top: 38px;
        .above-right {
          margin-left: 63px;
          li {
            line-height: 19px;
          }
        }
      }
    }
    .copy-right {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .pending {
        // width: 196px;
        height: 23px;
        line-height: 23px;
        border: 1px solid #ffffff;
        color: #ffff;
        font-size: 14px;
        padding: 0 5px;
      }
      .media {
        display: flex;
        .fb,
        .in {
          width: 25px;
          height: 25px;
          margin-left: 37px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
