<template>
  <router-view />
  <Footer />
</template>

<script>
import Footer from "@/components/common/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
};
</script>

<style lang="less">
@font-face {
  font-family: Abhaya Libre Bold;
  src: url("/fonts/AbhayaLibre-Bold.ttf");
}

@font-face {
  font-family: Abhaya Libre ExtraBold;
  src: url("/fonts/AbhayaLibre-ExtraBold.ttf");
}

@font-face {
  font-family: Abhaya Libre Medium;
  src: url("/fonts/AbhayaLibre-Medium.ttf");
}

@font-face {
  font-family: Abhaya Libre Regular;
  src: url("/fonts/AbhayaLibre-Regular.ttf");
}

@font-face {
  font-family: Abhaya Libre SemiBold;
  src: url("/fonts/AbhayaLibre-SemiBold.ttf");
}
@font-face {
  font-family: PingFang-SC;
  src: url("/fonts/PingFang-c-s.tff");
}

@font-face {
  font-family: PingFang-SC;
  src: url("/fonts/PingFang-zh-s.tff");
}

html,
body {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
#app {
  font-family: PingFang-SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-width: 1320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
